import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import SwiperGL from './swiper-gl';

const firstviewSwiper = new Swiper('.firstview_swiper', {
    modules: [SwiperGL],
    autoplay: {
        delay: 3000,
    },
    speed: 4000,
    // effect: 'fade',
    effect: 'gl',
    loop: true,
    gl: {
        shader: 'ripple',
    },
    // fadeEffect: {
    //     // crossFade: true
    // },
});

// const firstviewSwiperSp = new Swiper('.firstview_swiper_sp', {
//     modules: [SwiperGL],
//     autoplay: {
//         delay: 3000,
//     },
//     speed: 2500,
//     // effect: 'fade',
//     effect: 'gl',
//     gl: {
//         shader: 'ripple',
//     },
//     // fadeEffect: {
//     //     // crossFade: true
//     // },
// });





const companySwiperRl = new Swiper('.company_swiper_rl', {
    loop: true,
    slidesPerView: 1.5,
    speed: 23000,
    allowTouchMove: false,
    autoplay: {
        delay: 1,
    },
    breakpoints: {
        640: {
            slidesPerView: 3,
        }
    }

});


const companySwiperLr = new Swiper('.company_swiper_lr', {
    loop: true,
    slidesPerView: 1.5,
    speed: 23000,
    allowTouchMove: false,
    autoplay: {
        delay: 1,
        reverseDirection: true,
    },
    breakpoints: {
        640: {
            slidesPerView: 3,
        }
    }

});



const interviewSwiper = new Swiper('.interview_swiper', {
    loop: true,
    slidesPerView: 1.5,
    spaceBetween: 25,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        640: {
            slidesPerView: 3,
            spaceBetween: 45,
        }
    }
});



// 座談会
const memberSwiper = new Swiper('.member_swiper', {
    loop: false,
    slidesPerView: 1.1,
    spaceBetween: 0,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        761: {
            slidesPerView: 4,
            spaceBetween: 20,
        }
    }
});
